//Import default image
import defaultImage from "../../../assets/images/avatars/satoshi2.png"

// **  Initial State
const initialState = {
  userName: "",
  userImage: defaultImage,
  isLogged: false,
  kycPassed: false,
  isAmbassador: false,
  ambassadorId: null,
  totalClients: 0,
  totalAmount: 0,
  totalGains: 0,
  firstLevel: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLogged: true,
        userName: action.data,
        userImage: action.img,
        kycPassed: action.kyc,
        isAmbassador: action.isAmbassador,
        ambassadorId: action.ambassadorId,
        totalClients: action.totalClients,
        totalAmount: action.totalAmount,
        totalGains: action.totalGains,
        firstLevel: action.firstLevel
      }
    case 'GET_USER_INFO':
      return {
        ...state,
        userName: action.data,
        userImage: action.img,
        kycPassed: action.kyc,
        isAmbassador: action.isAmbassador,
        ambassadorId: action.ambassadorId,
        totalClients: action.totalClients,
        totalAmount: action.totalAmount,
        totalGains: action.totalGains,
        firstLevel: action.firstLevel
      }
    case 'PASS_KYC':
      return { ...state, kycPassed: true }
    case 'CHANGE_USER_IMG':
      return { ...state, userImage: action.img }
    case 'CHANGE_USER_NAME':
      return { ...state, userName: action.data }
    case 'LOGOUT':
      return {
        ...state,
        userName: "",
        userImage: null,
        isLogged: false,
        kycPassed: null
      }
    default:
      return state
  }
}

export default authReducer
