// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesEs from '@assets/data/locales/es.json'
// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...messagesEn },
  es: { ...messagesEs, ...messagesEs }
}

// ** Create Context
const Context = createContext()

// ** Returns Initial Menu Collapsed State
const initialLang = () => {
  const item = window.localStorage.getItem('lang')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : 'en'
}

const IntlProviderWrapper = ({ children }) => {

  // ** States
  const [locale, setLocale] = useState(initialLang())
  const [messages, setMessages] = useState(menuMessages[initialLang()])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    try {
      window.localStorage.setItem('lang', JSON.stringify(lang))
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={initialLang()}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
