// ** Initial State
const initialState = {
    userData: {
        userName: "Luis Mas",
        userEmail: "lluismas@bitbcn.org",
        isLogged: true
    }
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USER_INFO':
        return { ...state, userData: action.data }
      default:
        return state
    }
  }
  
  export default userReducer