// ** Logo
import belobaImg from '@src/assets/images/logo/tridente.svg'


const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100 d-flex justify-content-center' >
      <div className='w-100 text-center mb-5 d-flex justify-content-center'>
        <img className="belobaba-center" src={belobaImg}></img>
      </div>
      {/* <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div> */}
    </div>
  )
}

export default SpinnerComponent
